<template>
  <div class="header-container">
    <div class="search-warpper">
      <el-form :inline="true" :model="queryCopy">
        <el-form-item label="风格名称：">
          <el-input
            @change="searchChange"
            size="small"
            clearable
            style="width: 240px"
            placeholder="请输入风格名称"
            v-model="queryCopy.description"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select
            size="small"
            @change="searchChange"
            style="width: 240px"
            v-model="queryCopy.isEnabled"
            clearable
            placeholder="请选择状态"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="searchChange"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sup_this: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      queryCopy: {
        isEnabled: '',
        description: ''
      },
      merchantList: [],
      options: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: true,
          label: '启用'
        },
        {
          value: false,
          label: '禁用'
        }
      ]
    }
  },
  methods: {
    searchChange() {
      this.$emit('search', this.queryCopy)
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  .search-warpper {
    display: flex;
    font-size: 14px;
    align-items: center;
    color: #1a1a1a;
    margin: 20px 0;
    background-color: #f7f8fa;
    padding: 20px 20px 5px;
    .el-form-item {
      margin-right: 20px;
    }
  }
}
</style>
